:root {

  --white: #fefefe;
  --dark: #243133;
  --dark-blue: #003040;
  --red: #DC042B;
  --dark-red: #B50F24;
  --warm-red: #EB5C24;
  --mid-tone: #67707A;
  --light: #9AA5AF;
  --orange: #F39541;
  --light-blue: #61CADB;
  --yellow: #FDC318;


  --background-color: #f0f0f0;
  --primary-color: var(--dark-blue);
  --primary-contrast-color: #fefefe;
  --link-color: var(--light-blue);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
}

* {
  box-sizing: border-box;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Path: apps/frontend/src/App.css */
.hero {
  width: 100%;
  color: var(--primary-contrast-color);
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin: 0rem 0 2rem 0; */
  padding: 2rem;
  background-color: var(--primary-color);
  margin: 0 0 2rem 0;
}

.hero>* {
  max-width: 800px;
}

.hero h1 {
  font-size: 3em;
  text-align: center;
  margin: 0.5em 0 0.25rem;
  font-family: 'glober-semibold-free', sans-serif;
}

.hero h1+p {
  font-size: 1.2em;
  text-align: center;
  color: var(--primary-contrast-color);
}

.hero .search {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.hero .search input {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.hero .search input+div {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  gap: 0.5rem;
  font-size: 0.8rem;
}

.hero .search input+div a {
  text-decoration: underline;
  font-style: italic;
}

.hero .search ul {
  margin: 0;
  padding: 0;
  border-radius: 5px;
  display: block;
}

.hero .search ul li {

  list-style: none;
  margin: 0;
  padding: 0.75rem 1rem;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
}

.hero .search ul li a {
  color: var(--primary-color);
}

.hero .search ul li h6 {
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
}

.hero .search ul li p {
  font-size: 1.0rem;
  font-style: italic;

  padding: 0;
  margin: 0;
}

.description {
  padding-bottom: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.accelerators-page {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 1rem; 
}

.filter {
  position: sticky;
  align-self: start;
  justify-self: end;
  top: 3rem;
  background: white;
  padding: 1rem;
}

.select {
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 14px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content>* {
  max-width: 1024px;
}

.content .section.shortcuts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.content .section.shortcuts .card.shortcut {
  align-items: center;
  justify-content: space-between;
}

.content .section.shortcuts .card.shortcut .card-header {
  align-items: center;
}

.content .section.shortcuts .card.shortcut .card-header>* {
  text-align: center;
}

div.card {
  padding: 1.5rem 1.0rem;
  color: var(--primary-color);

  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

div.card .card-header {
  display: flex;
  flex-direction: column;
}

div.card .card-header svg.svg-inline--fa {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  color: var(--primary-color);
}

div.card .card-header h3 {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

div.card .card-header h3+span {
  padding: 0.5rem 0;
  font-size: 0.8rem;
  color: #777;
}

div.card .card-action a {
  padding: 0.5rem 0;
  font-size: 0.8rem;
}


input {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}


.button {
  margin: 0.1rem;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;

  color: var(--primary-color);
  background-color: var(--primary-contrast-color);
}
a:has(>.card){
  cursor: default;
}